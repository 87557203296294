@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@500&family=Manrope:wght@200;300;400;500&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Manrope", sans-serif;
  background: linear-gradient(3deg, #000000 10%, #5d2883 150%) fixed;
  color: white;
}

code {
  font-family: "Manrope", sans-serif;
}
