.error {
  width: 100%;
  color: red !important;
  font-size: 10px;
  margin-bottom: 0;
  display: block;
  word-wrap: break-word;
}

.main-card {
  width: 60vw;
  height: 60vh;
  background-color: white;
  border-radius: 30px;
  z-index: 2;
  padding: 2.5em;
  overflow: hidden;

  .container-card {
    height: 95%;
    width: 100%;
    overflow-y: scroll;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }
  .container-card::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .chooser-head {
    font-size: 30px;
  }

  .welcome-head {
    font-size: 40px;
    font-weight: 600;
    text-align: center;
  }

  .common {
    display: none;
  }

  #loading-embifi-logo {
    animation-name: loading-company-logo;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    display: block;
    margin-left: auto;
    margin-right: auto;
    // width: 10000px;
  }

  .container-content-center {
    height: 65%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn-submit {
    position: fixed;
    width: calc(60vw - 5rem);
    bottom: calc(20vh + 1.5rem);
  }

  .copy-option {
    position: fixed;
    top: calc(20vh + 1rem);
    right: calc(20vw + 4.5rem);

    button {
      padding: 0;
      border: none;
      background: none;
      margin-right: 10px;
      color: blue;
    }
  }
}

.form-input {
  height: 50px !important;
  background-color: #f3f8fd !important;
  border: none !important;
  padding: 20px !important ;
  border-radius: 3px !important;

  &:focus {
    outline: none;
  }
}

.form-input::placeholder {
  color: #b8bcca;
  opacity: 1;
  font-size: 11px;
  font-weight: 300;
}

@keyframes loading-company-logo {
  0% {
    width: 20%;
    height: auto;
  }
  50% {
    width: 0%;
    height: auto;
  }
  100% {
    width: 20%;
    height: auto;
  }
}

// pre {
//   white-space: pre-wrap; /* Since CSS 2.1 */
//   white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
//   white-space: -pre-wrap; /* Opera 4-6 */
//   white-space: -o-pre-wrap; /* Opera 7 */
//   word-wrap: break-word; /* Internet Explorer 5.5+ */
// }
